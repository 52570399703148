import { FC } from 'react';
import {
  ChakraProps,
  AspectRatio,
  Container,
  Flex,
  Box,
  BoxProps,
} from '@chakra-ui/react';
import { Author, Blogger, ContentImage, TeaserHeading } from '@/types';
import { useLayout } from '@/lib/hooks/useApp';
import Teaser from '../Teaser';
import { layoutMaxWidth, Overlap } from '../ArticleLayout';
import ArticleInfo from '../ArticleInfo';
// NOTE: always load Image for Hero due to priority
import Image from '../Image';

interface Props {
  image?: ContentImage;
  title?: string | null;
  leadText?: string | null;
  variant?: 'landing' | 'default' | 'listing';
  date?: string | null;
}

const getImageAspectRatio = (variant: Props['variant']) => {
  switch (variant) {
    case 'landing':
      return [375 / 447, 640 / 447, 1440 / 600];
    default:
      return [375 / 250, null, 960 / 300, 1280 / 300, 1440 / 300];
  }
};

const getContentContainerProps = (
  variant: Props['variant'],
  overlap?: null | Overlap,
) => {
  switch (variant) {
    case 'landing':
      return {
        maxW:
          overlap === 'expanded'
            ? layoutMaxWidth
            : layoutMaxWidth.map((l) => (l ? `min(${l}, 80ch)` : null)),
        sx: {
          '> *': {
            maxW: '80ch',
          },
          // safari only
          '@media not all and (min-resolution:.001dpcm)': {
            '@supports (-webkit-appearance:none)': {
              bottom: '1px', // Doesnt look 1:1 on Safari, but at least it doesn't hide the text on Osta page eg.
            },
          },
        },
      };
    default:
      return { maxW: layoutMaxWidth };
  }
};

// Same logic as with some other teasers: pass title to use title, otherwise pass heading. One of them is a must. TODO: make typings reflect that
export const HeroContent: FC<
  {
    titleVariant?: string;
    heading?: TeaserHeading;
    title?: TeaserHeading['title'];
    author?: Author | Blogger | null;
  } & Pick<Props, 'date' | 'leadText'> &
    Omit<ChakraProps, 'textStyle'>
> = ({
  title,
  heading,
  leadText,
  titleVariant = 'h2',
  date,
  children,
  author,
  ...props
}) => (
  <Teaser
    heading={
      (title
        ? { title, as: 'h1', variant: titleVariant }
        : heading) as TeaserHeading
    }
    lead={leadText}
    textStyle="lead"
    py={10}
    maxWidth="85ch"
    {...props}
  >
    {date && <ArticleInfo date={date} author={author} />}
    {children}
  </Teaser>
);

const Hero: FC<Props & BoxProps> = ({
  variant = 'default',
  image,
  title,
  leadText,
  children,
  className,
  date,
  ...rest
}) => {
  const { overlap } = useLayout();
  let content = null;
  if (title) {
    content = (
      <HeroContent
        {...{ title, leadText, date }}
        {...(image ? { color: 'white', titleVariant: 'h1' } : null)}
      >
        {children}
      </HeroContent>
    );
  }

  if (!image?.src && variant !== 'landing') {
    return content && <Box {...rest}>{content}</Box>;
  }

  return (
    <Flex
      layerStyle="fullWidth"
      flexDir="column"
      justifyContent="center"
      justifyItems="center"
      className={[className, 'hero'].filter(Boolean).join(' ')}
      overflow="hidden" // safari
      {...rest}
    >
      <AspectRatio
        ratio={getImageAspectRatio(variant)}
        height="100%"
        width="100%"
        position={content ? ['absolute', null, null, 'relative'] : 'relative'}
        m="0 0 auto"
      >
        <Image
          src={image?.src || '/'}
          alt={image?.alt || title}
          layout="fill"
          priority
          noRounding
        />
      </AspectRatio>
      {content ? (
        <Container
          {...getContentContainerProps(variant, overlap)}
          px="0"
          position={[null, null, null, 'absolute']}
          left="0"
          right="0"
          className="hero-content"
          zIndex="1"
        >
          {content}
        </Container>
      ) : null}
    </Flex>
  );
};

export default Hero;
