import dynamic from 'next/dynamic';
import {
  Field,
  SelectedEntriesField,
  HeroProps,
  KeyFigures as KeyFiguresProps,
  AccordionBlockProps,
  Newsletter as NewsletterProps,
  SearchPanel as SearchPanelProps,
  ListingBlock as ListingBlockProps,
  BotBlock as BotBlockProps,
  TrustmaryBlock as TrustmaryBlockProps,
  ListAgencies as ListAgenciesProps,
  PropertyTypeQuickLinks as PropertyTypeQuickLinksProps,
  ContentForm as ContentFormProps,
  KeySellingPoints as KeySellingPointsProps,
  SearchRealtorsAgencies as SearchRealtorsAgenciesProps,
  TodoList as TodoListProps,
  ProcessSection as ProcessSectionProps,
} from '@/types';

const HtmlContent = dynamic(() => import('../HtmlContent'));
// const HtmlButtons = dynamic(() => import('./HtmlButtons'));
// const Buttons = dynamic(() => import('../Buttons'));
const SelectedEntries = dynamic(() => import('./SelectedEntries'));
const AccordionBlock = dynamic(() => import('./AccordionBlock'));
const KeyFigures = dynamic(() => import('./KeyFigures'));
const HeroTeaser = dynamic(() => import('./HeroTeaser'));
const Newsletter = dynamic(() => import('./Newsletter'));
const SearchPanel = dynamic(() => import('./SearchPanel'));
const PropertyTypeQuickLinks = dynamic(
  () => import('@/components/frontend/PropertyTypeQuickLinks'),
);
const ListingBlock = dynamic(() => import('./ListingBlock'));
const ListingSection = dynamic(() => import('./ListingSection'));
const BotBlock = dynamic(() => import('./BotBlock'), { ssr: false });
const TrustmaryBlock = dynamic(() => import('./TrustmaryBlock'), {
  ssr: false,
});
const ListAgencies = dynamic(() => import('./ListAgencies'));
const ContentForm = dynamic(() => import('./ContentForm'));
const KeySellingPoints = dynamic(() => import('./KeySellingPoints'));
const TodoList = dynamic(() => import('./TodoList'));
const SearchRealtorsAgencies = dynamic(
  () => import('./SearchRealtorsAgencies'),
);
const ProcessSection = dynamic(() => import('./ProcessSection'));

interface Props {
  fields: Field[];
}

export const hasHero = (fields?: Field[]) => {
  const [maybeHero] = fields || [];
  return maybeHero?.__name === 'acf/hero' && maybeHero?.data;
};

export const resolveFields = (fields: Field[]): [null | HeroProps, Field[]] => {
  if (hasHero(fields)) {
    const [hero, ...contentFields] = fields;
    return [hero.data as HeroProps, contentFields];
  }
  return [null, fields];
};

/* eslint-disable react/no-array-index-key */
const Fields = ({ fields }: Props) => (
  <>
    {fields?.filter(Boolean).map(({ __name, html, data }, i) => {
      if (!__name) {
        throw new Error('Field must have a __name -prop defined');
      }
      switch (__name) {
        case 'acf/selected-entries':
          return (
            <SelectedEntries
              {...(data as SelectedEntriesField)}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/teaser-large':
          return <HeroTeaser {...(data as HeroProps)} key={`${__name}-${i}`} />;
        case 'core/paragraph':
          if (!html) {
            return null;
          }
          return <HtmlContent html={html} key={`${__name}-${i}`} />;
        case 'acf/accordion-block':
          return (
            <AccordionBlock
              {...(data as AccordionBlockProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/key-figures':
          return (
            <KeyFigures {...(data as KeyFiguresProps)} key={`${__name}-${i}`} />
          );
        case 'acf/form-content': {
          return (
            <ContentForm
              {...(data as ContentFormProps)}
              key={`${__name}-${i}`}
            />
          );
          break;
        }
        case 'acf/listing-block':
          return (
            <ListingBlock
              {...(data as ListingBlockProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/key-selling-points':
          return (
            <KeySellingPoints
              {...(data as KeySellingPointsProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'acf/todo-list':
          return (
            <TodoList {...(data as TodoListProps)} key={`${__name}-${i}`} />
          );
        case 'acf/process-section':
          return (
            <ProcessSection
              {...(data as ProcessSectionProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'mt/newsletter':
          return (
            <Newsletter {...(data as NewsletterProps)} key={`${__name}-${i}`} />
          );
        case 'mt/visualbot-block':
        case 'mt/bot-block':
          return (
            <BotBlock {...(data as BotBlockProps)} key={`${__name}-${i}`} />
          );
        case 'mt/trustmary-block':
          return (
            <TrustmaryBlock
              {...(data as TrustmaryBlockProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'mt/list-agencies-block':
          return (
            <ListAgencies
              {...(data as ListAgenciesProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'mt/search-listings':
          return (
            <SearchPanel
              {...(data as SearchPanelProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'mt/listing-section':
          return (
            <ListingSection
              {...(data as ListingBlockProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'mt/search-realtors-agencies':
          return (
            <SearchRealtorsAgencies
              {...(data as SearchRealtorsAgenciesProps)}
              key={`${__name}-${i}`}
            />
          );
        case 'mt/property-type-links':
          return (
            <PropertyTypeQuickLinks
              {...(data as PropertyTypeQuickLinksProps)}
              key={`${__name}-${i}`}
            />
          );
        default: {
          if (
            __name?.includes('acf/') ||
            __name?.includes('mhy/') ||
            __name?.includes('mt/')
          ) {
            console.warn('Missing field resolver for: ', __name);
          }
          if (!html) {
            return null;
          }
          return <HtmlContent html={html} key={`${__name}-${i}`} />;
        }
      }
    })}
  </>
);

export default Fields;
