import dynamic from 'next/dynamic';
import { Post } from '@/types';
import Fields, { resolveFields } from '@/components/frontend/Fields';
import ContentSection from '@/components/frontend/ContentSection';
import ArticleLayout, {
  FieldsLayout,
} from '@/components/frontend/ArticleLayout';
import Hero, { HeroContent } from '@/components/frontend/Hero';

const SocialShare = dynamic(() => import('@/components/frontend/SocialShare'), {
  ssr: false,
});

export const splitFields = (
  fields: Post['fields'],
): { basicFields: Post['fields']; sectionFields: Post['fields'] } =>
  fields.reduce(
    (acc, { __name, ...field }) => {
      const key =
        __name?.startsWith('core') ||
        __name?.includes('accordion') ||
        __name?.includes('form-content') ||
        __name?.includes('todo-list') ||
        __name?.includes('buttons')
          ? 'basicFields'
          : 'sectionFields';
      return {
        ...acc,
        [key]: [...acc[key], { ...field, __name }],
      };
    },
    {
      basicFields: [],
      sectionFields: [],
    },
  );

const Page = ({
  fields,
  post_title: title,
  post_excerpt: leadText,
  featured_image,
  shareable,
}: Post) => {
  const [hero, contentFields] = resolveFields(fields);
  const { basicFields, sectionFields } = splitFields(contentFields);

  const { image, ...heroProps } = hero
    ? {
        title: hero.heading || title,
        leadText: hero.text || leadText,
        image: hero.image || featured_image,
      }
    : { title, leadText, image: featured_image };

  /* Content is basic content and section content. Basic content is on white bg and sections on the 'fallback' bg.
   * Semantically they probably both belong in article.
   * The grid-action created by Layout components is so that components dont have to deal with horizontal paddings at all and
   * the gaps are consistent all-around
   * Individually components can use different background via the FullSection -component
   */
  const content = (
    <>
      {basicFields.length ? (
        <ContentSection>
          <HeroContent
            {...heroProps}
            borderTopRadius="lg"
            px={['1rem', null, 10]}
            background="white"
            data-padded-content
          />
          <Fields fields={basicFields} />
          {shareable ? <SocialShare title={title} /> : null}
        </ContentSection>
      ) : (
        <HeroContent {...heroProps} pb="0" />
      )}
      {sectionFields.length ? (
        <FieldsLayout sectionsLayout>
          <Fields fields={sectionFields} />
        </FieldsLayout>
      ) : null}
    </>
  );
  if (!image) {
    return (
      <FieldsLayout as="article" bg="white">
        {content}
      </FieldsLayout>
    );
  }
  return (
    <ArticleLayout>
      <Hero image={image} />
      <FieldsLayout bg="white">{content}</FieldsLayout>
    </ArticleLayout>
  );
};

export default Page;
