import { FC, forwardRef } from 'react';
import { Box, GridProps, HeadingProps } from '@chakra-ui/react';
import dynamic from 'next/dynamic';

const Heading = dynamic(() => import('./Heading'));

const getAsideWidth = (contentWidth: string) =>
  `calc((var(--chakra-sizes-container-2xl) - ${contentWidth}) / 2)`;

const getGrid = (contentWidth: string) => [
  null,
  null,
  `
    [gutter] auto
    [aside] minmax(15ch, ${getAsideWidth(contentWidth)})
    [padding] var(--chakra-space-10)
    [content] minmax(45ch, ${contentWidth})
    [padding] var(--chakra-space-10)
    [aside] minmax(15ch, ${getAsideWidth(contentWidth)})
    [gutter] auto
  `,
];

const ContentSection = forwardRef<
  HTMLDivElement,
  GridProps & { contentWidth?: string }
>(({ contentWidth = '84ch', sx, ...props }, ref) => (
  <Box
    ref={ref}
    gridTemplateColumns={getGrid(contentWidth)}
    display={[null, null, 'grid']}
    sx={{
      '> *:not([data-padded-content])': { gridColumn: 'content' },
      '> [data-padded-content]': { gridColumn: 'padding / 6' },
      '@media print': { pt: 4 },
      ...sx,
    }}
    mx="auto"
    {...props}
  />
));

export const SectionHeading: FC<HeadingProps> = (props) => (
  <Heading variant="h5" mb={[2, 5]} {...props} gridColumn="aside !important" />
);

export default ContentSection;
