import { ChakraProps, Flex, Text } from '@chakra-ui/react';
import { Author, Blogger } from '@/types';
import { TeaserAuthor } from './Author';

interface ArticleInfoProps {
  date?: string;
  author?: Author | Blogger | null;
}

const ArticleInfo = ({
  date,
  author,
  ...rest
}: ArticleInfoProps & ChakraProps) => {
  if (author) {
    return (
      <TeaserAuthor author={author}>
        <>{date && <Text as="time">{date}</Text>}</>
      </TeaserAuthor>
    );
  }
  if (!date) {
    return null;
  }
  return (
    <Flex color="secondaryColor" as={Text} textStyle="xs" {...rest}>
      {date && <time>{date}</time>}
    </Flex>
  );
};
export default ArticleInfo;
